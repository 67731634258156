<template>
    <div class="shop-section">
        <template v-if="!slidesLoaded">
            <skeleton-box v-for="n in 3"
                        :key="n"
                        height="400px"
                        width="100%" />
        </template>
        <template v-else>
            <a v-for="banner in banners " :key="banner.id" :href="banner.path">
                <banner :banner="banner" />
            </a>
        </template>
    </div>
</template>

<script>
import SkeletonBox from '@/components/SkeletonBox'
import Banner from './Banner.vue'

export default {
    name: 'Banners',
    components: {
        Banner,
        SkeletonBox
    },
    props: {
        slidesLoaded: {
            type: Boolean,
            default: false
        },
        banners: {
            type: Array,
            required: true
        }
    }
}
</script>

<style type="scss" scoped>
    .shop-section {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        grid-gap: 10px;
    }

    @media only screen and (max-width: 600px) {
        .shop-section {
            grid-template-columns: 1fr;
        }
    }
</style>
