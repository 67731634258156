<template>
    <div>
        <nuxt-img width="500"
                  height="750"
                  fit="contain"
                  quality="80"
                  :src="bannerImage"
                  :alt="banner.alternative_text"
                  loading="lazy"
                  class="responsive"/>

        <div class="name">{{ banner.name }}</div>
    </div>
</template>

<script>
import LazyImg from '@/../shared/components/LazyImg'
import isMobile from '@/mixins/windowSize'

export default {
    name: 'Banner',
    mixins: [isMobile],
    components: {
        LazyImg
    },
    props: {
        banner: {
            type: Object,
            required: true
        }
    },
    computed: {
        bannerImage() {
            if (this.isMobile) {
                return this.banner.mobile_image.url || this.banner.image.url
            } else {
                return this.banner.image.url
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .name {
        font-family: Tenez;
        font-size: 2.4rem;
        line-height: 1.21;
        letter-spacing: 1.2px;
        text-align: center;
        color: #151515;
        margin-top: 25px;
    }
</style>
